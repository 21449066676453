import EditContent from "../../../components/master/Branch/EditContent";

const EditBranch = () => {
  return (
    <>
      <EditContent />
    </>
  );
};

export default EditBranch;
