import Content from "../../../components/portal/library/Content";

const AddLibrary = () => {
  return (
    <>
      <Content />
    </>
  );
};

export default AddLibrary;
