import Content from "../../../components/portal/faqs/Content";

const AddFaqs = () => {
  return (
    <>
      <Content />
    </>
  );
};

export default AddFaqs;
