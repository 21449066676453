import ViewContent from "../../../components/portal/biological_info/ViewContent";

const ViewBiologicalInformation = () => {
  return (
    <>
      <ViewContent />
    </>
  );
};

export default ViewBiologicalInformation;
