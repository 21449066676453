import ViewContent from "../../../components/portal/rajyapal/ViewContent";

const ViewRajyapal = () => {
  return (
    <>
      <ViewContent />
    </>
  );
};

export default ViewRajyapal;
