import ViewHistory from "../../../components/home/workflow/ViewHistory";

const ViewWorkflowHistory = () => {
  return (
    <>
      <ViewHistory />
    </>
  );
};

export default ViewWorkflowHistory;
