import EditContent from "../../../components/portal/rajyapal/EditContent";

const EditRajyapal = () => {
  return (
    <>
      <EditContent />
    </>
  );
};

export default EditRajyapal;
