import ViewContent from "../../../components/portal/legislative_members/ViewContent";

const ViewLegislativeMember = () => {
  return (
    <>
      <ViewContent />
    </>
  );
};

export default ViewLegislativeMember;
