import EditContent from "../../../components/home/feedbacks/EditContent";

const EditFeedbacks = () => {
  return (
    <>
      <EditContent />
    </>
  );
};

export default EditFeedbacks;
