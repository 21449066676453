import EditContent from "../../../components/portal/legislative_council/EditContent";

const EditLegislativeCouncil = () => {
  return (
    <>
      <EditContent />
    </>
  );
};

export default EditLegislativeCouncil;
