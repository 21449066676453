import AddContent from "../../../components/master/navigation/AddContent";

const AddNavigation = () => {
  return (
    <>
      <AddContent />
    </>
  );
};

export default AddNavigation;
