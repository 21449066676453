import Content from "../../../components/home/seo/Content";

const AddSEO = () => {
  return (
    <>
      <Content />
    </>
  );
};

export default AddSEO;
