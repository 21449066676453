import ViewContent from "../../../components/portal/legislative_assembly/ViewContent";

const ViewLegislativeAssembly = () => {
  return (
    <>
      <ViewContent />
    </>
  );
};

export default ViewLegislativeAssembly;
