import EditContent from "../../../components/portal/faqs/EditContent";

const EditFaqs = () => {
  return (
    <>
      <EditContent />
    </>
  );
};

export default EditFaqs;
