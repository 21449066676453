import Content from "../../../components/portal/houses/Content";

const AddHouses = () => {
  return (
    <>
      <Content />
    </>
  );
};

export default AddHouses;
