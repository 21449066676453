import Content from "../../../components/portal/vidhan_mandal/Content";

const AddVidhanMandal = () => {
  return (
    <>
      <Content />
    </>
  );
};

export default AddVidhanMandal;
