import ViewContent from "../../../components/home/feedbacks/ViewContent";

const ViewFeedbacks = () => {
  return (
    <>
      <ViewContent />
    </>
  );
};

export default ViewFeedbacks;
