import EditInterestContent from "../../../components/portal/interest_request/EditInterestContent";

const AddInterest = () => {
  return (
    <>
      <EditInterestContent />
    </>
  );
};

export default AddInterest;
