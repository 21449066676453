import EditContent from "../../../components/portal/houses/EditContent";

const EditHouses = () => {
  return (
    <>
      <EditContent />
    </>
  );
};

export default EditHouses;
