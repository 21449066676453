import EditContent from "../../../components/master/constituencyTypes/EditContent";

const EditConstituencyTypes = () => {
  return (
    <>
      <EditContent />
    </>
  );
};

export default EditConstituencyTypes;
