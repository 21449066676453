import ViewContent from "../../../components/master/gender/ViewContent";

const ViewGender = () => {
  return (
    <>
      <ViewContent />
    </>
  );
};

export default ViewGender;
