import Content from "../../../components/master/department/Content";

const AddDepartment = () => {
  return (
    <>
      <Content />
    </>
  );
};

export default AddDepartment;
