import EditContent from "../../../components/portal/gallery/EditContent";

const EditGallery = () => {
  return (
    <>
      <EditContent />
    </>
  );
};

export default EditGallery;
