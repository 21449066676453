import Content from "../../../components/master/party/Content";

const AddPoliticalParties = () => {
  return (
    <>
      <Content />
    </>
  );
};

export default AddPoliticalParties;
