import ViewContent from "../../../components/portal/legislative_council/ViewContent";

const ViewLegislativeCouncil = () => {
  return (
    <>
      <ViewContent />
    </>
  );
};

export default ViewLegislativeCouncil;
