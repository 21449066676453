import EditContent from "../../../components/portal/library_doc/EditContent";

const EditLibraryDoc = () => {
  return (
    <>
      <EditContent />
    </>
  );
};

export default EditLibraryDoc;
