import Content from "../../../components/portal/portal_users/Content";

const AddPortalUsers = () => {
  return (
    <>
      <Content />
    </>
  );
};

export default AddPortalUsers;
