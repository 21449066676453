import EditContent from "../../../components/master/district/EditContent";

const EditDistrict = () => {
  return (
    <>
      <EditContent />
    </>
  );
};

export default EditDistrict;
