import ViewContent from "../../../components/home/workflow/ViewContent";

const ViewFaqs = () => {
  return (
    <>
      <ViewContent />
    </>
  );
};

export default ViewFaqs;
