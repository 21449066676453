import ViewContent from "../../../components/master/department/ViewContent";

const ViewDepartment = () => {
  return (
    <>
      <ViewContent />
    </>
  );
};

export default ViewDepartment;
