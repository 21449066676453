import ViewContent from "../../../components/master/party/ViewContent";

const ViewPoliticalParties = () => {
  return (
    <>
      <ViewContent />
    </>
  );
};

export default ViewPoliticalParties;
