import EditContent from "../../../components/master/gender/EditContent";

const EditGender = () => {
  return (
    <>
      <EditContent />
    </>
  );
};

export default EditGender;
