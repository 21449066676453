import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import docs from "../../../images/document.png";
import add from "../../../images/add.svg";

const ViewContent = () => {
  const [directories, setDirectories] = useState([]);
  const [folderName, setFolderName] = useState("");
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const navigate = useNavigate();

  useEffect(() => {
    // Update folderName state when params change
    setFolderName(params.get("folder") || "");
  }, [params]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://mlsapi.sblcorp.com/image/${folderName}`
        );
        if (response.data.success) {
          setDirectories(response.data.data);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [folderName]);

  const handleHomeClick = () => {
    setFolderName(""); // Set folderName to an empty string when Home is clicked
    navigate("/ViewFileManager"); // Update the URL to remove the "folder" query parameter
  };

  return (
    <div className="content-wrapper pt-4">
      <div className="contentofpages">
        {folderName && (
          <button className="addpagess">
            <img src={add} style={{ width: "25px" }} alt="add" />
            Upload
          </button>
        )}
        <h4 className="page-title">• File Manager</h4>
        <div className="card card-info" style={{ padding: "30px" }}>
          <a>
            <i className="fa fa-home" /> &gt;{" "}
            <span style={{ cursor: "pointer" }} onClick={handleHomeClick}>
              Home
            </span>{" "}
            / <span>{folderName ? folderName + " /" : ""}</span>
          </a>

          <div className="file-managerrrr">
            <div className="row">
              {directories &&
                directories?.map((item, index) => (
                  <React.Fragment key={index}>
                    <div className="col-lg-4 mb-3">
                      {item.type === "directory" ? (
                        <Link
                          to={`/ViewFileManager?folder=${item.name}`}
                          className="col-lg-4"
                        >
                          <i className="fa-solid fa-folder"></i>
                          <p>{item.name}</p>
                        </Link>
                      ) : (
                        <a
                          href={`https://mlsapi.sblcorp.com/images/${folderName}/${item.name}`}
                          className="col-lg-4"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            className="mb-3"
                            style={{
                              width: "100px",
                              height: "100px",
                              objectFit: "cover",
                            }}
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null;
                              currentTarget.src = docs;
                            }}
                            src={`https://mlsapi.sblcorp.com/images/${folderName}/${item.name}`}
                          />
                          <p>{item.name}</p>
                        </a>
                      )}
                    </div>
                  </React.Fragment>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewContent;
