import EditContent from "../../../components/master/presidingofficers/EditContent";

const EditPresidingOfficers = () => {
  return (
    <>
      <EditContent />
    </>
  );
};

export default EditPresidingOfficers;
