import AddContent from "../../../components/master/legislativepositions/AddContent";

const AddLegislativePositions = () => {
  return (
    <>
      <AddContent />
    </>
  );
};

export default AddLegislativePositions;
