import Content from "../../../components/portal/rajyapal/Content";

const AddRajyapal = () => {
  return (
    <>
      <Content />
    </>
  );
};

export default AddRajyapal;
