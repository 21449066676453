import ViewContent from "../../../components/master/Committee/ViewContent";

const ViewCommittee = () => {
  return (
    <>
      <ViewContent />
    </>
  );
};

export default ViewCommittee;
