import Content from "../../../components/portal/legislative_assembly/Content";

const AddLegislativeAssembly = () => {
  return (
    <>
      <Content />
    </>
  );
};

export default AddLegislativeAssembly;
