import ViewContent from "../../../components/master/Branch/ViewContent";

const ViewBranch = () => {
  return (
    <>
      <ViewContent />
    </>
  );
};

export default ViewBranch;
