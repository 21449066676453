import EditContent from "../../../components/portal/vidhan_mandal/EditContent";

const EditVidhanMandal = () => {
  return (
    <>
      <EditContent />
    </>
  );
};

export default EditVidhanMandal;
