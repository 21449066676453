import EditContent from "../../../components/master/constituency/EditContent";

const EditConstituency = () => {
  return (
    <>
      <EditContent />
    </>
  );
};

export default EditConstituency;
