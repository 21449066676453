import EditContent from "../../../components/master/party/EditContent";

const EditPoliticalParties = () => {
  return (
    <>
      <EditContent />
    </>
  );
};

export default EditPoliticalParties;
