import EditContent from "../../../components/master/department/EditContent";

const EditDepartment = () => {
  return (
    <>
      <EditContent />
    </>
  );
};

export default EditDepartment;
