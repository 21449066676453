import Content from "../../../components/portal/session_calendar/Content";

const AddSessionCalendar = () => {
  return (
    <>
      <Content />
    </>
  );
};

export default AddSessionCalendar;
