import EditContent from "../../../components/master/sessionField/EditContent";

const EditNavigation = () => {
  return (
    <>
      <EditContent />
    </>
  );
};

export default EditNavigation;
