import Content from "../../../components/portal/library_doc/Content";

const AddLibraryDoc = () => {
  return (
    <>
      <Content />
    </>
  );
};

export default AddLibraryDoc;
