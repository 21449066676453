import ViewContent from "../../../components/master/presidingofficers/ViewContent";

const ViewPresidingOfficers = () => {
  return (
    <>
      <ViewContent />
    </>
  );
};

export default ViewPresidingOfficers;
