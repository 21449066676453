import Content from "../../../components/portal/biological_info/Content";

const AddBiologicalInformation = () => {
  return (
    <>
      <Content />
    </>
  );
};

export default AddBiologicalInformation;
