import EditContent from "../../../components/master/assembly/EditContent";

const EditAssembly = () => {
  return (
    <>
      <EditContent />
    </>
  );
};

export default EditAssembly;
