import Content from "../../../components/home/workflow/Content";

const AddFaqs = () => {
  return (
    <>
      <Content />
    </>
  );
};

export default AddFaqs;
