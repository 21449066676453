import EditContent from "../../../components/master/Committee/EditContent";

const EditCommittee = () => {
  return (
    <>
      <EditContent />
    </>
  );
};

export default EditCommittee;
