import Content from "../../../components/master/gender/Content";

const AddGender = () => {
  return (
    <>
      <Content />
    </>
  );
};

export default AddGender;
