import EditContent from "../../../components/master/legislativepositions/EditContent";

const EditLegislativePositions = () => {
  return (
    <>
      <EditContent />
    </>
  );
};

export default EditLegislativePositions;
