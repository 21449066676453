import ViewContent from "../../../components/master/ministry/ViewContent";

const ViewMinistry = () => {
  return (
    <>
      <ViewContent />
    </>
  );
};

export default ViewMinistry;
