import Content from "../../../components/master/constituency/Content";

const AddConstituency = () => {
  return (
    <>
      <Content />
    </>
  );
};

export default AddConstituency;
