import Content from "../../../components/portal/mantri_mandal/Content";

const AddMantriMandal = () => {
  return (
    <>
      <Content />
    </>
  );
};

export default AddMantriMandal;
