import ViewContent from "../../../components/master/district/ViewContent";

const ViewDistrict = () => {
  return (
    <>
      <ViewContent />
    </>
  );
};

export default ViewDistrict;
