import ViewGalleryImage from "../../../components/portal/gallery/ViewGalleryImage";

const ViewGalleryImages = () => {
  return (
    <>
      <ViewGalleryImage />
    </>
  );
};

export default ViewGalleryImages;
