import EditContent from "../../../components/portal/mantri_mandal/EditContent";

const EditMantriMandal = () => {
  return (
    <>
      <EditContent />
    </>
  );
};

export default EditMantriMandal;
