import EditContent from "../../../components/portal/library/EditContent";

const EditLibrary = () => {
  return (
    <>
      <EditContent />
    </>
  );
};

export default EditLibrary;
