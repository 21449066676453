import Content from "../../../components/master/assembly/Content";

const AddAssembly = () => {
  return (
    <>
      <Content />
    </>
  );
};

export default AddAssembly;
