import ViewContent from "../../../components/master/constituencyTypes/ViewContent";

const ViewConstituencyTypes = () => {
  return (
    <>
      <ViewContent />
    </>
  );
};

export default ViewConstituencyTypes;
