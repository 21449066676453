import EditContent from "../../../components/master/ministry/EditContent";

const EditMinistry = () => {
  return (
    <>
      <EditContent />
    </>
  );
};

export default EditMinistry;
