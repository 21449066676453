import EditContent from "../../../components/home/helpdesk/Editcontent";

const EditHelpdesk = () => {
  return (
    <>
      <EditContent />
    </>
  );
};

export default EditHelpdesk;
