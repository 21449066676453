import EditContent from "../../../components/home/seo/EditContent";

const EditSEO = () => {
  return (
    <>
      <EditContent />
    </>
  );
};

export default EditSEO;
