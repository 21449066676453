import Content from "../../../components/portal/gallery/Content";

const AddGallery = () => {
  return (
    <>
      <Content />
    </>
  );
};

export default AddGallery;
