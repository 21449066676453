import ViewInterest from "../../../components/portal/interest_request/ViewInterest";

const AddInterest = () => {
  return (
    <>
      <ViewInterest />
    </>
  );
};

export default AddInterest;
