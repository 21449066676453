import AddContent from "../../../components/master/presidingofficers/AddContent";

const AddPresidingOfficers = () => {
  return (
    <>
      <AddContent />
    </>
  );
};

export default AddPresidingOfficers;
