import ViewContent from "../../../components/home/helpdesk/ViewContent";

const ViewHelpdesk = () => {
  return (
    <>
      <ViewContent />
    </>
  );
};

export default ViewHelpdesk;
