import Content from "../../../components/portal/portal_users/EditUserProfile";

const Block = () => {
  return (
    <>
      <Content />
    </>
  );
};

export default Block;
