import Content from "../../../components/master/Committee/Content";

const AddCommittee = () => {
  return (
    <>
      <Content />
    </>
  );
};

export default AddCommittee;
