import EditContent from "../../../components/portal/portal_users/EditContent";

const EditPortalUsers = () => {
  return (
    <>
      <EditContent />
    </>
  );
};

export default EditPortalUsers;
