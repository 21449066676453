import ViewContent from "../../../components/master/legislativepositions/ViewContent";

const ViewLegislativePositions = () => {
  return (
    <>
      <ViewContent />
    </>
  );
};

export default ViewLegislativePositions;
