import EditContent from "../../../components/portal/biological_info/EditContent";

const EditBiologicalInformation = () => {
  return (
    <>
      <EditContent />
    </>
  );
};

export default EditBiologicalInformation;
