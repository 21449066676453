import Content from "../../../components/portal/legislative_council/Content";

const AddLegislativeCouncil = () => {
  return (
    <>
      <Content />
    </>
  );
};

export default AddLegislativeCouncil;
