import Content from "../../../components/portal/debate/Content";

const AddDebate = () => {
  return (
    <>
      <Content />
    </>
  );
};

export default AddDebate;
