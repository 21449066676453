import ViewContent from "../../../components/portal/mantri_mandal/ViewContent";

const ViewMantriMandal = () => {
  return (
    <>
      <ViewContent />
    </>
  );
};

export default ViewMantriMandal;
