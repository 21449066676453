import EditContent from "../../../components/portal/legislative_members/EditContent";

const EditLegislativeMember = () => {
  return (
    <>
      <EditContent />
    </>
  );
};

export default EditLegislativeMember;
