import AddContent from "../../../components/master/ministry/Content";

const AddMinistry = () => {
  return (
    <>
      <AddContent />
    </>
  );
};

export default AddMinistry;
