import Content from "./Content";

const Main = () => {
  return (
    <div>
      <Content />
    </div>
  );
};
export default Main;
