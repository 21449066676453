import ViewContent from "../../../components/master/constituency/ViewContent";

const ViewConstituency = () => {
  return (
    <>
      <ViewContent />
    </>
  );
};

export default ViewConstituency;
