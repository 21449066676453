import EditRequestContent from "../../../components/portal/interest_request/EditRequestContent";

const AddInterest = () => {
  return (
    <>
      <EditRequestContent />
    </>
  );
};

export default AddInterest;
