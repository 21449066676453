import Content from "../../../components/portal/portal_users/EditUserReset";

const UserReset = () => {
  return (
    <>
      <Content />
    </>
  );
};

export default UserReset;
