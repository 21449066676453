import Content from "../../../components/portal/legislative_members/Content";

const AddLegislativeMembers = () => {
  return (
    <>
      <Content />
    </>
  );
};

export default AddLegislativeMembers;
