import ViewContent from "../../../components/portal/vidhan_mandal/ViewContent";

const ViewVidhanMandal = () => {
  return (
    <>
      <ViewContent />
    </>
  );
};

export default ViewVidhanMandal;
