import ViewContent from "../../../components/home/seo/ViewContent";

const ViewSEO = () => {
  return (
    <>
      <ViewContent />
    </>
  );
};

export default ViewSEO;
