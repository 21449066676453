import Content from "../../../components/master/district/Content";

const AddDistrict = () => {
  return (
    <>
      <Content />
    </>
  );
};

export default AddDistrict;
